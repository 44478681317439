/* global google */
import ToursList from "./class/ToursList";
import Selectr from "mobius1-selectr";
import * as responsive from "./lib/responsive";

export default function() {
  new ToursList(document.querySelector("#tours-list"));

  const selectbox = document.getElementById("filters__destination");
  const selector = new Selectr(selectbox, { searchable: false });

  selector.on("selectr.change", () => {
    const event = document.createEvent("HTMLEvents");
    event.initEvent("change", true, false);
    selectbox.dispatchEvent(event);
  });

  let zoom = 7;
  if (responsive.breakPointUnder("380")) zoom = 5;
  else if (responsive.breakPointDown("sm")) zoom = 6;
  else if (responsive.breakPointDown("md")) zoom = 7;

  window.initToursMap = () => {
    const center = {
      lat: 46.227638,
      lng: 2.287592000000018,
    };

    const map = new google.maps.Map(
      document.getElementById("tours-map-container"),
      {
        zoom: zoom,
        center,
        fullscreenControl: false,
        disableDefaultUI: true,
        streetViewControl: false,
      }
    );

    const markers = JSON.parse(
      document
        .querySelector("#tours-map-container")
        .getAttribute("data-markers")
    );

    const icon = {
      url: "/images/icons/marker.png",
      scaledSize: new google.maps.Size(33, 53),
      anchor: new google.maps.Point(16, 53),
    };

    markers.forEach(item => {
      new google.maps.Marker({
        position: { lat: item.lat, lng: item.lng },
        map: map,
        icon: icon,
      });
    });
  };
}
