import SearchList from "./class/SearchList";
import Selectr from "mobius1-selectr";

export default function() {
  new SearchList(document.querySelector("#search-list"));

  const selectbox = document.getElementById("filters__price");
  const selector = new Selectr(selectbox, {searchable: false});

  selector.on("selectr.change", () => {
    const event = document.createEvent("HTMLEvents");
    event.initEvent("change", true, false);
    selectbox.dispatchEvent(event);
  });
}