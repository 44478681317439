import $ from "jquery";
import ExempleCarousel from "./class/ExempleCarousel";


export default function initCarousel() {

  const slider = $(".carousel-container");
  const carousel = document.querySelector(".carousel-container");
  const carouselItems = document.querySelectorAll(".carousel-item");

  new ExempleCarousel(carousel, carouselItems, slider);

}
