/* global google */
import $ from "jquery";
import Selectr from "mobius1-selectr";

export default function(page) {
  window.initContactMap = () => {
    const center = {
      lat: 48.8811888,
      lng: 2.331978499999991,
    };

    const map = new google.maps.Map(
      document.getElementById("contact-map-container"),
      {
        zoom: 15,
        center,
        fullscreenControl: false,
        disableDefaultUI: true,
        streetViewControl: false,
      }
    );

    new google.maps.Marker({
      position: center,
      map: map,
    });
  };

  const selectbox = document.querySelector(
    '[name="appbundle_contact[subject]"]'
  );

  new Selectr(selectbox, { searchable: false });

  function destroyTooltips() {
    $(".form-cell").each((i, item) => {
      const input = $(item).find(".form-input");

      if (input.data("tooltipsterNs")) {
        input.tooltipster("destroy");
      }
    });
  }

  function initializeTooltip(name) {
    const input = $(name);
    const cell = input.parent();
    const errorContainer = cell.find(".error-tooltip");

    if (errorContainer.length > 0) {
      errorContainer.addClass("hidden");

      if (input.data("tooltipsterNs")) {
        input.tooltipster("destroy");
      }

      input.tooltipster({
        content: errorContainer,
        contentAsHTML: true,
        trigger: "click",
      });
    }
  }

  const form = page.find('form[name="appbundle_contact"]');

  form.on("submit", e => {
    e.preventDefault();

    const formData = form.serialize();

    const listTemplate = $('<ul class="error-tooltip hidden"></ul>');
    const listItemTemplate = $("<li></li>");

    form
      .find('[type="submit"]')
      .prop("disabled", true)
      .addClass("btn--loading");

    $.post("#", formData, res => {
      form.find(".error-tooltip").remove();
      $(".has-error").removeClass("has-error");
      destroyTooltips();

      form
        .find('[type="submit"]')
        .prop("disabled", false)
        .removeClass("btn--loading");

      if (res.success === false && res.errors) {
        Object.keys(res.errors).forEach(key => {
          const errorsList = listTemplate.clone();
          const errorsItems = res.errors[key].map(item => {
            const errorItem = listItemTemplate.clone();
            return errorItem.html(item);
          });

          errorsList.append(errorsItems);

          const name = `[name="appbundle_contact[${key}]"]`;

          form.find(name).before(errorsList);
          form
            .find(name)
            .parent()
            .addClass("has-error");
          initializeTooltip(name);
        });
      } else if (res.success === true) {
        form.get(0).reset();

        $(".page-contact-form").prepend(
          `<div class="alert alert-success">${res.message}</div>`
        );
      }
    });
  });
}
