import $ from 'jquery';

export default function(page) {
  const mainImage = page.find('.tourImages-main__img');

  const imagesTour = $('.tourImages-thumbs__img');

  imagesTour.click(function () {
    let oldMainImage = mainImage.attr('src');
    let newMainImage = $(this).attr('src');

    mainImage.attr('src', newMainImage);
    $(this).attr('src', oldMainImage);
  });
}