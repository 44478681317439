import $ from "jquery";
import animateOnScroll from "./lib/animateOnScroll";
import "slick-carousel";
import "whatwg-fetch";
import "tooltipster";
import tours from "./tours";
import searchTours from "./searchTours";
import contact from "./contact";
import objectFit from "object-fit";
import newsletter from "./newsletter";
import initCarousel from "./initCarousel";
import tourDetail from "./tourDetails.js";
import CookiesDisclaimer from "./CookiesDisclaimer";

animateOnScroll(".animated");
newsletter();
const page = $("body");

if (page.hasClass("page-tours")) {
  tours();
}
if (page.hasClass("page-search")) {
  searchTours(page);
}
if (page.hasClass("page-contact")) {
  contact(page);
}
if (page.hasClass("tour-detail")) {
  tourDetail(page);
}
if ($(".carousel-container").length) {
  initCarousel();
}

const fixedHeaderClass = "fixed-header";

window.addEventListener("scroll", e => {
  if (e.pageY > 0) {
    page.addClass(fixedHeaderClass);
  } else {
    page.removeClass(fixedHeaderClass);
  }
});

objectFit.polyfill({
  selector: ".tourPreview-media img",
  fittype: "cover",
  disableCrossDomain: "true",
});

const burgerButton = $(".navbar-burger");
const navBar = $(".navbar-menu");

burgerButton.on("click", () => {
  burgerButton.find(".icon-burger").toggleClass("icon-burger--x");
  navBar.toggleClass("navbar-menu--active");
  page.toggleClass("no-scroll");
});

$(".pageInsert-container").slick({
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1087,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 3000,
      },
    },
  ],
});

if (document.querySelector(".CookiesDisclaimer")) {
  new CookiesDisclaimer(document.querySelector(".CookiesDisclaimer"));
}
