import Cookies from "js-cookie";

const COOKIE_NAME = "accept_cookies";

export default class CookiesDisclaimer {
  constructor(container) {
    this.container = container;

    this.close = this.close.bind(this);

    this.button = this.container.querySelector(".CookiesDisclaimer-button");

    this.button.addEventListener("click", this.close);

    if (!this.isAccepted()) {
      this.open();
    }
  }

  isAccepted() {
    return Cookies.get(COOKIE_NAME);
  }

  open() {
    this.container.classList.add("CookiesDisclaimer--visible");
  }

  accept() {
    Cookies.set(COOKIE_NAME, 1, { expires: 365, path: "/" });
  }

  close() {
    this.accept();

    this.container.classList.remove("CookiesDisclaimer--visible");

    window.setTimeout(() => this.destroy(), 300);
  }

  destroy() {
    const instance = this;

    this.container.parentNode.removeChild(this.container);

    instance.button.removeEventListener("click", this.close);

    delete instance.container;
    delete instance.button;
    delete instance.close;

    instance.destroyed = true;
  }
}
