import $ from "jquery";
import "slick-carousel";
import * as responsive from "../lib/responsive";

class ExempleCarousel {
  constructor(container, items, slider) {
    this.carousel = container;
    this.items = items;
    this.slider = slider;

    this.devices = ["mobile", "tablet", "desktop"];

    this.curDevice = null;

    this.initCarousel();
  }

  initCarousel() {
    this.renderCarousel();

    window.addEventListener('resize',() => {
      this.switchDevice();
    });
  }

  switchDevice() {
    //switching to mobile
    if (this.curDevice!=null && this.curDevice != this.devices[0]
      && (this.curDevice == this.devices[1] || this.curDevice == this.devices[2])
      && responsive.breakPointUnder("809")) {

      this.renderCarousel();
    }
    //switching to tablet
    else if (this.curDevice!=null && this.curDevice != this.devices[1]
      && (this.curDevice == this.devices[0] || this.curDevice == this.devices[2])
      && responsive.breakPointBetween(809, responsive.lg-1)) {

      this.renderCarousel();
    }
    //switching to desktop
    else if (this.curDevice!=null && this.curDevice != this.devices[2]
      && (this.curDevice == this.devices[0] || this.curDevice == this.devices[1])
      && responsive.breakPointUp("xl")) {
      this.renderCarousel();
    }
  }

  renderCarousel() {
    //init slick
    if (this.slider.hasClass("slick-slider")) $(".carousel-container").slick('unslick');
    this.carousel.innerHTML = "";

    if (responsive.breakPointUnder("809")) {
      //construct carousel to disp 1 items per slide in mobile
      for (let i = 0 ; i < this.items.length ; i++) {
        this.carousel.appendChild(this.items[i]);
      }
      $(".carousel-item").removeClass("animated slideUp");

      this.startSlick();

      this.curDevice = this.devices[0];
    }
    else if (responsive.breakPointDown("lg")) {
      // construct carousel to disp 2 items per slide in tablet
      this.renderCarouselWithRow(2);
      this.startSlick();

      this.curDevice = this.devices[1];
    }
    else {
      // construct carousel in desktop
      const itemsPerSlide = this.slider.data("itemsperslide");
      this.renderCarouselWithRow(itemsPerSlide);
      this.startSlick();

      this.curDevice = this.devices[2];
    }
  }

  startSlick() {
    this.slider.removeClass("animated slideUp");

    this.slider.slick({
      arrows: true,
      nextArrow: $(".carousel-arrow--next"),
      prevArrow: $(".carousel-arrow--prev"),
      infinite: false,
    });
  }

  renderCarouselWithRow(nbItems = 3) {
    let j = -1;

    for (let i = 0 ; i < this.items.length ; i++) {
      if (i % nbItems == 0) {
        const newCarouselRow = document.createElement("div");
        newCarouselRow.className = "carousel-row";
        this.carousel.appendChild(newCarouselRow);
        j++;
      }
      const carouselRow = document.querySelectorAll(".carousel-row");
      carouselRow[j].appendChild(this.items[i]);
    }
  }

}

export default ExempleCarousel;