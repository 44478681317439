export const lg = 1200;
export const md = 992;
export const sm = 768;
export const xs = 576;

export function breakPointOnly(point) {
  let minSize, maxSize;

  if (point === "xl") {
    return breakPointUp("xl");
  } else if (point === "lg") {
    minSize = md; maxSize = lg-1;
  } else if (point === "md") {
    minSize = sm; maxSize = md-1;
  } else if (point === "sm") {
    minSize = xs; maxSize = sm-1;
  } else if (point === "xs") {
    return breakPointDown("xs");
  }

  return window.matchMedia("(min-width: " + minSize + "px) and (max-width: " + maxSize + "px)").matches;
}

export function breakPointUp(point) {
  let minWidth;

  if (point === "xl") {
    minWidth = lg;
  } else if (point === "lg") {
    minWidth = md;
  } else if (point === "md") {
    minWidth = sm;
  } else if (point === "sm") {
    minWidth = xs;
  }

  return window.matchMedia("(min-width: " + minWidth + "px)").matches;
}

export function breakPointDown(point) {
  let maxWidth;

  if (point === "lg") {
    maxWidth = lg-1;
  } else if (point === "md") {
    maxWidth = md-1;
  } else if (point === "sm") {
    maxWidth = sm-1;
  } else if (point === "xs") {
    maxWidth = xs-1;
  }

  return window.matchMedia("(max-width: " + maxWidth + "px)").matches;
}

export function breakPointUnder(maxWidth) {
  return window.matchMedia("(max-width: " + maxWidth + "px)").matches;
}

export function breakPointBetween(minWidth, maxWidth) {
  return window.matchMedia("(min-width: " + minWidth + "px) and (max-width: " + maxWidth + "px)").matches;
}