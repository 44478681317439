import $ from "jquery";
import CustomEvent from "custom-event";
import isInViewport from "in-viewport";

class ToursList {
  constructor(element) {
    this.container = element;
    this.state = {
      page: 1,
      destination: null,
      onlySale: false,
      showSpinner: true,
    };

    this.itemsCount = 0;

    const locale = document.querySelector("html").getAttribute("lang");
    this.url = `/${locale}/tours/data`;
    this.noSaleTemplate = null;
    this.saleTemplate = null;
    this.insertTemplate = null;
    this.spinnerTemplate = null;
    this.emtpyTemplate = null;

    this.button = document.getElementById("tours-list-button");

    this.fetch = this.fetch.bind(this);
    this.onFetch = this.onFetch.bind(this);
    this.showOrHideItems = this.showOrHideItems.bind(this);
    this.handleFilterUpdate = this.handleFilterUpdate.bind(this);

    this.loadTemplates();

    this.button.addEventListener("click", () => {
      this.button.setAttribute("disabled", "disabled");
      this.button.classList.add("btn--loading");

      this.setState({
        page: this.state.page + 1,
      });
    });

    this.filters = {
      destination: document.querySelector("[name='filters[destination]']"),
      onlySale: document.querySelector("[name='filters[only_sale]']"),
    };

    document
      .querySelectorAll(".toursList-filter")
      .forEach(filter =>
        filter.addEventListener("change", this.handleFilterUpdate)
      );

    window.addEventListener("scroll", this.showOrHideItems);

    this.fetch();
  }

  setState(nextState) {
    this.state = {
      ...this.state,
      ...nextState,
    };

    this.fetch();
  }

  fetch() {
    if (this.state.showSpinner === true) {
      this.container.innerHTML = this.spinnerTemplate;
      this.container.classList.add("toursList--loading");
      this.hideButton();
    }

    fetch(`${this.url}?${$.param(this.state)}`)
      .then(res => res.json())
      .then(this.onFetch);
  }

  showOrHideItems() {
    const items = this.container.querySelectorAll(".tourPreview");

    items.forEach(item => {
      if (!isInViewport(item)) {
        item.classList.add("hidden");
      } else {
        item.classList.remove("hidden");
      }
    });
  }

  hideButton() {
    this.button.classList.remove("btn--loading");
    this.button.classList.add("btn--hidden");
  }

  showButton() {
    this.button.classList.remove("btn--hidden");
  }

  onFetch(data) {
    if (this.container.classList.contains("toursList--empty")) {
      this.container.classList.remove("toursList--empty");
      this.container.innerHTML = "";
    }

    if (this.state.showSpinner === true) {
      this.container.innerHTML = "";
      this.container.classList.remove("toursList--loading");
      this.showButton();

      this.state.showSpinner = false;
    }

    if (data && data.length > 0) {
      this.button.removeAttribute("disabled");
      this.button.classList.remove("btn--loading");

      data.forEach(item => {
        if (this.itemsCount === 8) {
          const insert = this.renderTemplate(this.insertTemplate);
          this.container.appendChild(insert);
        }

        const template = item.hasSale ? this.saleTemplate : this.noSaleTemplate;

        const element = this.renderTemplate(template, item);

        this.container.appendChild(element);
        this.itemsCount = this.container.children.length;

        window.dispatchEvent(new CustomEvent("newChildren"));
      });
    } else {
      if (this.state.page === 1) {
        this.container.classList.add("toursList--empty");
        this.container.innerHTML = this.emtpyTemplate;
      }

      this.hideButton();
    }
  }

  loadTemplates() {
    this.saleTemplate = document.querySelector("#tour-preview-sale").innerHTML;

    this.noSaleTemplate = document.querySelector(
      "#tour-preview-no-sale"
    ).innerHTML;

    this.insertTemplate = document.querySelector("#contact-insert").innerHTML;

    this.spinnerTemplate = document.querySelector(
      "#spinner-template"
    ).innerHTML;

    this.emtpyTemplate = document.querySelector("#empty-message").innerHTML;
  }

  renderTemplate(template, data = null) {
    if (data) {
      const keys = Object.keys(data);

      template = template.replace(/%7B/g, "{");
      template = template.replace(/%20/g, " ");
      template = template.replace(/%7D/g, "}");

      keys.forEach(key => {
        const re = new RegExp(`{{ ${key} }}`, "g");
        template = template.replace(re, data[key]);
      });
    }

    const tmp = document.createElement("div");
    tmp.innerHTML = template;

    return tmp.firstChild;
  }

  handleFilterUpdate() {
    this.itemsCount = 0;

    this.setState({
      destination: parseInt(this.filters.destination.value, 10) || null,
      page: 1,
      onlySale:
    this.filters.onlySale &&
    this.filters.onlySale.value &&
    this.filters.onlySale.checked,
      showSpinner: true,
    });
  }
}

export default ToursList;
